exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation && location.hash) {
    const targetElement = document.querySelector(location.hash);
    if (targetElement) {
      setTimeout(() => {
        const yOffset = -96; // Adjust the buffer value as needed
        window.scrollTo({
          top:
            targetElement.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset,
          behavior: "auto",
        });
      }, 0);
    }
  } else {
    window.scrollTo(0, 0);
  }
};
