exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affordability-js": () => import("./../../../src/pages/affordability.js" /* webpackChunkName: "component---src-pages-affordability-js" */),
  "component---src-pages-environmental-social-governance-js": () => import("./../../../src/pages/environmental-social-governance.js" /* webpackChunkName: "component---src-pages-environmental-social-governance-js" */),
  "component---src-pages-equity-js": () => import("./../../../src/pages/equity.js" /* webpackChunkName: "component---src-pages-equity-js" */),
  "component---src-pages-financials-js": () => import("./../../../src/pages/financials.js" /* webpackChunkName: "component---src-pages-financials-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quality-js": () => import("./../../../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */)
}

